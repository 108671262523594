import { gql } from '@apollo/client';

export const GET_USER_MISSED_OUTBOUND_CALL_FOLLOW_UP_SETTINGS = gql`
  query getUserMissedOutboundCallFollowUpSetting(
    $userId: String!
    $placeId: String
  ) {
    user(userId: $userId) {
      id
      missedOutboundCallFollowUpEnabled
      missedOutboundCallFollowUpMessage
      missedOutboundCallFollowUpRenderedMessage(placeId: $placeId)
    }
  }
`;
