import cloneDeep from 'lodash/cloneDeep';
import { createSelector } from 'reselect';
import './types';
import type { WebReducerState } from './types';

type ModalState = {
  id: string;
  enabled: boolean;
  metadata: any;
};

export type ViewReducerState = {
  modals: Array<ModalState>;
};

export const showModal = (id: string, metadata: any) => ({
  type: 'VIEW/SHOW_MODAL',
  id,
  metadata,
});

export const hideModal = (id: string) => ({
  type: 'VIEW/HIDE_MODAL',
  id,
});

export const updateModalData = (id: string, metadata: any) => ({
  type: 'VIEW/UPDATE_MODAL_DATA',
  id,
  metadata,
});

function findModalById(modals: Array<ModalState>, modalId: string) {
  if (!modals) return null;
  return modals.find(modal => modal.id === modalId);
}

export const getModalById = (state: WebReducerState, id: string) =>
  findModalById(state.views.modals, id);

export const getIsModalEnabled: (
  arg0: WebReducerState,
  arg1: string,
) => boolean = createSelector([getModalById], modal =>
  modal ? modal.enabled : false,
);

export function getIsAnyModalEnabled(state: WebReducerState): boolean {
  const { modals } = state.views;
  if (!modals) return false;
  return modals.some(modal => modal.enabled);
}

export const getModalMetadata: (arg0: WebReducerState, arg1: string) => any =
  createSelector([getModalById], modal => (modal ? modal.metadata : null));

export const Modals = {
  ADD_AUTOPILOT: 'TEMPLATES/ADD_AUTOPILOT',
  ADD_CUSTOMER: 'TEMPLATES/ADD_CUSTOMER',
  ADD_STATUS_ITEM: 'TEMPLATES/ADD_STATUS_ITEM',
  ADD_USER: 'ADD_USER',
  AUTOMATION_PUBLISH_ERROR: 'AUTOMATION_PUBLISH_ERROR',
  APPOINTMENT_AGENT_SETTINGS_DELETE_MODAL:
    'APPOINTMENT_AGENT_SETTINGS_DELETE_MODAL',
  APPOINTMENT_AGENT_SETTINGS_ADD_MODAL: 'APPOINTMENT_AGENT_SETTINGS_ADD_MODAL',
  APPOINTMENT_AGENT_SETTINGS_DUPLICATE_MODAL:
    'APPOINTMENT_AGENT_SETTINGS_DUPLICATE_MODAL',
  APPOINTMENT_AGENT_SETTINGS_RENAME_MODAL:
    'APPOINTMENT_AGENT_SETTINGS_RENAME_MODAL',
  APPOINTMENT_AGENT_SETTINGS_SYNC_MODAL:
    'APPOINTMENT_AGENT_SETTINGS_SYNC_MODAL',
  APPOINTMENT_REPORT_MISTAKE: 'TEMPLATES/APPOINTMENT_REPORT_MISTAKE',
  COMPLETE_AUTOPILOT: 'TEMPLATES/COMPLETE_AUTOPILOT',
  COMPLIANCE_DETAILS: 'TEMPLATES/COMPLIANCE_DETAILS',
  COMPOSE: 'TEMPLATES/COMPOSE',
  DELETE_AUTOMATION_MODAL: 'DELETE_AUTOMATION_MODAL',
  DELETE_AUTOMATION_ACTION_MODAL: 'DELETE_AUTOMATION_ACTION_MODAL',
  EDIT_STATUS_ITEM: 'TEMPLATES/EDIT_STATUS_ITEM',
  RATING_MODAL: 'RATING_MODAL',
  INBOUND_CALL: 'INBOUND_CALL',
  LABEL_CONVERSATION: 'TEMPLATES/LABEL_CONVERSATION',
  LABEL_EDITOR: 'TEMPLATES/LABEL_EDITOR',
  NUMA_SUGGESTS: 'TEMPLATES/NUMA_SUGGESTS',
  NUMA_SUGGESTS_ADVANCED: 'TEMPLATES/NUMA_SUGGESTS_ADVANCED',
  NUMA_SUMMARIZATION: 'TEMPLATES/NUMA_SUMMARIZATION',
  NUMOBILE_SOFT_LANDING: 'NUMOBILE_SOFT_LANDING',
  OUTBOUND_CONFERENCE_CALL: 'OUTBOUND_CONFERENCE_CALL',
  OUTBOUND_CONFERENCE_CALL_AUTO_TEXT: 'OUTBOUND_CONFERENCE_CALL_AUTO_TEXT',
  OUTBOUND_CONFERENCE_CALLING: 'OUTBOUND_CONFERENCE_CALLING',
  OUTBOUND_CONFERENCE_CALLING_ADD_NUMER:
    'OUTBOUND_CONFERENCE_CALLING_ADD_NUMER',
  OUTBOUND_CONFERENCE_CALLING_DISCLAIMER:
    'OUTBOUND_CONFERENCE_CALLING_DISCLAIMER',
  PAYMENT_ATTACHMENT: 'TEMPLATES/PAYMENT_ATTACHMENT',
  PAYMENT_CAPTURE: 'TEMPLATES/PAYMENT_CAPTURE',
  PAYMENT_RECEIPT: 'TEMPLATES/PAYMENT_RECEIPT',
  PAYMENT_REFUND: 'TEMPLATES/PAYMENT_REFUND',
  PAYMENT_REQUEST: 'TEMPLATES/PAYMENT_REQUEST',
  PREVIEW_AUTOPILOT_MILESTONE: 'TEMPLATES/PREVIEW_AUTOPILOT_MILESTONE',
  REVIEW_PROMPT: 'YELP/REVIEW_PROMPT',
  SEND_FILE: 'TEMPLATES/SEND_FILE',
  SEND_IMAGE_NOTE: 'TEMPLATES/SEND_IMAGE_NOTE',
  SEND_IMAGE: 'TEMPLATES/SEND_IMAGE',
  SEND_LINK: 'YELP/SEND_LINK',
  SEND_MEDIA: 'TEMPLATES/NEW_CONVERSATION_SEND_MEDIA',
  SEND_OFFERS: 'YELP/SEND_OFFERS',
  SEND_REVIEW: 'TEMPLATES/SEND_REVIEW',
  SEND_SAT_SURVEY: 'TEMPLATES/SEND_SAT_SURVEY',
  SEND_STAGE_NOTIFICATION: 'TEMPLATES/SEND_STAGE_NOTIFICATION',
  SEND_VCARD: 'TEMPLATES/SEND_VCARD',
  STATUS_BOARD_LOG_CALL: 'TEMPLATES/STATUS_BOARD_LOG_CALL',
  VERIFY_ACCOUNT: 'VERIFY_ACCOUNT/STEP1',
  VIDEO_UPLOAD_CENTER: 'TEMPLATES/VIDEO_UPLOAD_CENTER',
  VIDEO_UPLOAD_ITEM: 'TEMPLATES/VIDEO_UPLOAD_ITEM',
  VISA_CHECKOUT: 'TEMPLATES/VISA_CHECKOUT',
} as const;

function initialModalState(modalId: string): ModalState {
  return {
    id: modalId,
    enabled: false,
    metadata: null,
  };
}

export const VIEW_INITIAL_STATE: ViewReducerState = {
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  modals: Object.keys(Modals).map(key => initialModalState(Modals[key])),
};

type ViewReducerActions =
  | {
      type: 'VIEW/SHOW_MODAL';
      id: string;
      metadata: any;
    }
  | {
      type: 'VIEW/HIDE_MODAL';
      id: string;
    }
  | {
      type: 'VIEW/UPDATE_MODAL_DATA';
      id: string;
      metadata: any;
    };

const ViewReducer = (
  state: ViewReducerState = VIEW_INITIAL_STATE,
  action: ViewReducerActions,
) => {
  switch (action.type) {
    case 'VIEW/SHOW_MODAL': {
      const match = findModalById(state.modals, action.id);
      if (!match) return state;

      const modals = cloneDeep(state.modals);
      const update = findModalById(modals, action.id);
      if (update) {
        update.enabled = true;
        update.metadata = action.metadata;
      }

      return {
        ...state,
        modals,
      };
    }

    case 'VIEW/HIDE_MODAL': {
      const match = findModalById(state.modals, action.id);
      if (!match) return state;

      const modals = cloneDeep(state.modals);
      const update = findModalById(modals, action.id);
      if (update) update.enabled = false;

      return {
        ...state,
        modals,
      };
    }

    case 'VIEW/UPDATE_MODAL_DATA': {
      const match = findModalById(state.modals, action.id);
      if (!match) return state;

      const modals = cloneDeep(state.modals);
      const update = findModalById(modals, action.id);
      if (update) {
        update.metadata = action.metadata;
      }

      return {
        ...state,
        modals,
      };
    }

    default: {
      return state;
    }
  }
};

export default ViewReducer;
