import gql from 'graphql-tag';

export const GET_CALL_RECORDS = gql`
  query getCallRecords(
    $placeIds: [String!]!
    $userIds: [String!]
    $first: Int
    $after: String
    $createdBefore: ArrowField
    $createdAfter: ArrowField
    $onlyCallIntelligenceEligible: Boolean
    $callDirection: String
    $sortBy: String
    $sortDirection: String
  ) {
    callRecords(
      placeIds: $placeIds
      userIds: $userIds
      first: $first
      after: $after
      createdBefore: $createdBefore
      createdAfter: $createdAfter
      onlyCallIntelligenceEligible: $onlyCallIntelligenceEligible
      callDirection: $callDirection
      sortBy: $sortBy
      sortDirection: $sortDirection
    ) {
      edges {
        node {
          id
          callType
          createdOn
          callDuration
          placeName
          returnCallsRoutingOutcome
          staffDisplayName
          customerDisplayName
          customerPhoneNumber
          transcript {
            id
            subject
            summary
            analysis {
              id
              scores {
                id
                name
                rating
              }
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
    }
  }
`;
