import { gql } from '@apollo/client';
import { TranscriptAnalysisFragment } from './transcriptAnalysis.gql';

export const OutboundConferenceCallFragment = gql`
  fragment OutboundConferenceCallFields on OutboundConferenceCallQL {
    id
    createdOn
    caller {
      id
      displayName
    }
    conversationId
    transcript {
      id
      events {
        id
        type
      }
      subject
      summary
      analysis {
        ...TranscriptAnalysisFields
      }
    }
    outboundCallSid
    hostMdn
    recipientMdn
    recipientDisplayName
    endTime
    startTime
    callStatus
    callDuration
    isBeingRecorded
    hasCallAssist
    callRecording {
      id
      url
    }
    answeredByVoicemail
  }
  ${TranscriptAnalysisFragment}
`;
