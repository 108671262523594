import { gql } from '@apollo/client';

export const UPDATE_USER_MISSED_CALL_FOLLOW_UP_SETTINGS = gql`
  mutation updateMissedCallFollowUpSetting(
    $input: UpdateMissedCallFollowUpSettingInput!
  ) {
    updateMissedCallFollowUpSetting(input: $input) {
      user {
        id
        missedOutboundCallFollowUpEnabled
        missedOutboundCallFollowUpMessage
        missedOutboundCallFollowUpRenderedMessage
      }
    }
  }
`;
