import { gql } from '@apollo/client';

import { UserItemAndNotificationFragment } from '../../fragments/gql/userItem.gql';
import { SkuEntitlementsFragment } from '../../fragments/gql/skuEntitlements.gql';
import { AwayModeFragment } from '../../fragments/gql/AwayModeFragment.gql';
import { InboxBehaviourFragment } from '../../fragments/gql/InboxBehaviourFragment.gql';
import { UserViewPreferencesFragment } from '../../fragments/gql/UserViewPreferences.gql';

export const GET_USER = gql`
  query getUser($userId: String!) {
    user(userId: $userId) {
      ...UserItemAndNotificationFields
      ...AwayModeFields
      ...InboxBehaviourFields
      jobRole
      jobTitle
      signature
      signaturePreference
      status
      hasStatusItems
      hasExternalIdentity
      viewPreferences {
        ...UserViewPreferencesFields
      }
      missedOutboundCallFollowUpEnabled
      missedOutboundCallFollowUpMessage
      missedOutboundCallFollowUpRenderedMessage
    }
    skuEntitlements {
      ...SkuEntitlementsFields
    }
  }
  ${InboxBehaviourFragment}
  ${UserItemAndNotificationFragment}
  ${SkuEntitlementsFragment}
  ${AwayModeFragment}
  ${UserViewPreferencesFragment}
`;
