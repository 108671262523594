import { maybe, validate, ValidationResult } from 'tcomb-validation';
import values from 'lodash/values';
import t from 'tcomb';

// Ensures that there aren't too many properties provided to the object
const strictValidation = { strict: true };

// Keep OBJECTS and ACTIONS in sync with numbox
// https://github.com/NumberAI/numbox/blob/master/numbox/analytics.py
export const OBJECTS = {
  ADD_USER: 'Add User',
  ADVISOR_VIEW: 'Advisor View',
  SMART_STATUS_UPDATE_PLACE_SETTINGS: 'Smart Status Update Place Settings',
  SMART_STATUS_UPDATE_CONVERSATION_SETTINGS:
    'Smart Status Update Conversation Settings',
  ASSISTANT: 'Assistant',
  AWAY_MODE: 'Away Mode',
  AWAY_MODE_SCHEDULE: 'Away Mode Schedule',
  COMMAND_K: 'Command K',
  COMPOSE: 'Compose',
  COMPOSER_TEXT_GENERATION: 'Composer Text Generation',
  COMPOSER_MESSAGE: 'Composer Message',
  COMPOSER_MODE: 'Composer Mode',
  COMPOSER_SHORTCUT: 'Composer Shortcut',
  COMPOSER_TOOLBAR_ACTION: 'Composer Toolbar Action',
  CONVERSATION_BACK_BUTTON: 'Conversation Back Button',
  CONVERSATION_LABEL: 'Conversation Label',
  CONVERSATION_NAVIGATION_ARROW: 'Conversation Navigation Arrow',
  CONVERSATION_SUMMARY: 'Conversation summary',
  CONTACT_DETAILS: 'Contact Details',
  CONVERSATION: 'Conversation',
  DEFAULT_VIEW: 'Default View',
  ENGAGEMENT_SUMMARY: 'Engagement Summary',
  RO_HISTORY: 'Repair Order History',
  FACEBOOK_CHANNEL: 'Facebook Channel',
  INBOX: 'Inbox',
  INSIGHTS_PAGE: 'Insights page',
  LOGIN: 'Login',
  MANAGER_DASHBOARD_TEAM_TABLE_ROW: 'Manager Dashboard Team Table Row',
  MANAGER_DASHBOARD_PLACE_SUMMARY_ROW: 'Manager Dashboard Place Summary Row',
  MANAGER_DASHBOARD_STATS_CARD: 'Manager Dashboard Place Summary Stats Card',
  MANAGER_DASHBOARD_JOB_ROLE_FILTER: 'Manager Dashboard Job Role Filter',
  MAIN_MENU_NAV_BUTTON: 'Main Menu Navigation Button',
  MENTION: 'Mention',
  MESSAGE: 'Message',
  MESSAGE_REASON_SENT: 'Message Reason Sent',
  NUMA_NAV_LOGO: 'Numa Nav Logo',
  OUTBOUND_CALL: 'Outbound Call',
  PAGE: 'Page',
  REPORT_QUESTION_BOT_MISTAKE: 'Reports Appointment Bot Mistake',
  SCHEDULED_MESSAGE: 'Scheduled Message',
  SAVED_REPLY: 'Saved Reply',
  SEARCH: 'Search',
  SETTINGS_SCREEN: 'Settings Screen',
  SHORTCUTS: 'Shortcuts',
  SIDE_BAR: 'Side Bar',
  SIDE_BAR_INBOX_BUTTON: 'Side Bar Inbox Button',
  SUGGESTION: 'Suggestion',
  TEXT_EXPAND: 'Text expansion',
  THEME_MODE: 'Theme Mode',
  TO_DO_BANNER: 'To-Do Banner',
  TWILIO_CHANNEL: 'Twilio Channel',
  USER: 'User',
  VIDEO_CONFERENCE_URL: 'Video Conference URL',
  VIEW_PREFERENCE: 'View Preference',
  WRITE_IT_FOR_ME: 'Write it for me',
};

export const ACTIONS = {
  ABORTED: 'Aborted',
  ADDED: 'Added',
  ARCHIVED: 'Archived',
  CANCELLED: 'Cancelled',
  CHANGED: 'Changed',
  CHANGED_ACCOUNT: 'Changed Account',
  CHANGED_TEAM: 'Changed Team',
  CLICKED: 'Clicked',
  CLOSED: 'Closed',
  CLOSED_ALL_COVERSATIONS: 'Closed all conversations',
  COMPLETED: 'Completed',
  CONFIGURED: 'Configured',
  CREATED: 'Created',
  CUBE_QUERY_ENDED: 'Cube query ended',
  CUBE_QUERY_STARTED: 'Cube query started',
  CUBE_QUERY_CANCELED: 'Cube query canceled',
  DELETED: 'Deleted',
  DELIVERED: 'Delivered',
  DISABLED: 'Disabled',
  EDITED: 'Edited',
  ENABLED: 'Enabled',
  EXPANDED: 'Expanded',
  FAILED: 'Failed',
  FEEDBACK_RECEIVED: 'Feedback Received',
  FILTERED: 'Filtered',
  GENERATED: 'Generated',
  HIDDEN: 'Hidden',
  INSERTED: 'Inserted',
  LOADED: 'Loaded',
  LOG_IN_FAILED: 'Login Failed',
  LOGGED_IN: 'Logged In',
  LOGGED_OUT: 'Logged Out',
  MARK_ALL_AS_READ: 'Marked All As Read',
  NAVIGATED_BACK: 'Navigated Back',
  OPENED: 'Opened',
  PERFORMED: 'Performed',
  RATED: 'Rated',
  READ: 'Read',
  RECEIVED: 'Received',
  REMOVED: 'Removed',
  SELECTED: 'Selected',
  SENT: 'Sent',
  SENT_AHEAD: 'Sent Ahead',
  SHOWN: 'Shown',
  SORTED: 'Sorted',
  SPUN: 'Spun',
  STEP: 'Step',
  STARTED: 'Started',
  TAGGED: 'Tagged',
  TOGGLED: 'Toggled',
  UPLOADED: 'Uploaded',
  UPDATED: 'Updated',
  VIEWED: 'Viewed',

  // Compose Actions
  ADDRESSED_TO: 'Addressed To',
  ADDRESSED_FROM: 'Addressed From',
};

const WithAnalyticsProperties = {
  accountId: t.String,
  accountName: t.String,
  userId: t.String,
  userEmail: t.String,
  themeMode: t.String,
};

export const PROPERTIES_TYPE = {
  [OBJECTS.ADVISOR_VIEW]: t.struct({
    ...WithAnalyticsProperties,
    enabled: maybe(t.Boolean),
  }),

  [OBJECTS.SMART_STATUS_UPDATE_PLACE_SETTINGS]: t.struct({
    ...WithAnalyticsProperties,
    placeId: t.String,
    // which field the user specifically modified
    changedFieldName: maybe(t.String),
    isEnabled: maybe(t.Boolean),
    evaluationTimeValue: maybe(t.String),
    evaluationTimeEnabled: maybe(t.Boolean),
    detailLevel: maybe(t.Number),
  }),

  [OBJECTS.SMART_STATUS_UPDATE_CONVERSATION_SETTINGS]: t.struct({
    ...WithAnalyticsProperties,
    placeId: maybe(t.String),
    conversationId: maybe(t.String),
    isEnabled: maybe(t.Boolean),
  }),

  [OBJECTS.AWAY_MODE]: t.struct({
    newStatus: t.String,
    snoozeType: maybe(t.String),
  }),

  [OBJECTS.AWAY_MODE_SCHEDULE]: t.struct({
    newStatus: maybe(t.String),
  }),

  [OBJECTS.COMMAND_K]: t.struct({
    ...WithAnalyticsProperties,
    accountId: maybe(t.String),
    placeId: maybe(t.String),
    conversationId: maybe(t.String),
    action: maybe(t.String),
  }),

  [OBJECTS.COMPOSE]: t.struct({
    ...WithAnalyticsProperties,
    accountId: maybe(t.String),
    composeId: maybe(t.String),
    placeId: maybe(t.String),
    type: maybe(t.String),
    to: maybe(t.String),
    channelId: maybe(t.String),
  }),

  [OBJECTS.COMPOSER_MESSAGE]: t.struct({
    ...WithAnalyticsProperties,
    method: t.String,
    mode: t.String,
  }),

  [OBJECTS.COMPOSER_MODE]: t.struct({
    ...WithAnalyticsProperties,
    mode: t.String,
    from: t.String,
    to: t.String,
  }),

  [OBJECTS.COMPOSER_SHORTCUT]: t.struct({
    ...WithAnalyticsProperties,
    action: t.String,
    mode: t.String,
  }),

  [OBJECTS.COMPOSER_TEXT_GENERATION]: t.struct({
    ...WithAnalyticsProperties,
    conversationId: maybe(t.String),
    // added though does not exist for now
    generationId: t.String,
    unsentMessageText: maybe(t.String),
    repairOrderId: maybe(t.String),
    /**
     * the style of text generation: free-form, status-update, tree, etc.
     */
    style: maybe(t.String),
    generatedContent: maybe(t.String),
    acceptedText: maybe(t.String),
    didChangeText: maybe(t.Boolean),
    isSelectedAnswersIdenticalToDefaultBranch: maybe(t.Boolean),
    intent: maybe(t.String),
    newIntent: maybe(t.String),
    additionalDetails: maybe(t.String),
  }),

  [OBJECTS.COMPOSER_TOOLBAR_ACTION]: t.struct({
    ...WithAnalyticsProperties,
    action: t.String,
    mode: t.String,
    conversationId: maybe(t.String),
  }),

  [OBJECTS.CONTACT_DETAILS]: t.struct({
    ...WithAnalyticsProperties,
    participantId: t.String,
    firstName: t.Boolean,
    lastName: t.Boolean,
    contactNotes: t.Boolean,
  }),

  [OBJECTS.CONVERSATION]: t.struct({
    conversationId: t.String,
    customerId: t.String,
    source: maybe(t.String),
    accountId: maybe(t.String),
    placeId: maybe(t.String),
    hasRecentHeatCase: maybe(t.Boolean),
  }),

  [OBJECTS.CONVERSATION_BACK_BUTTON]: t.struct({
    ...WithAnalyticsProperties,
    inboxId: t.String,
    sourcePagePath: t.String,
    targetPagePath: t.String,
  }),

  [OBJECTS.CONVERSATION_LABEL]: t.struct({
    ...WithAnalyticsProperties,
    labelName: t.String,
    isNewLabel: t.Boolean,
    conversationId: t.String,
  }),

  [OBJECTS.CONVERSATION_NAVIGATION_ARROW]: t.struct({
    direction: t.String,
    prevConversationId: t.String,
    newConversationId: t.String,
  }),

  [OBJECTS.DEFAULT_VIEW]: t.struct({
    ...WithAnalyticsProperties,
    newDefaultView: t.String,
  }),

  [OBJECTS.ENGAGEMENT_SUMMARY]: t.struct({
    ...WithAnalyticsProperties,
    conversationId: t.String,
    engagementId: t.String,
    summaryId: t.String,
    summaryText: t.String,
    rating: t.Number,
    reason: maybe(t.String),
    details: maybe(t.String),
  }),

  [OBJECTS.RO_HISTORY]: t.struct({
    ...WithAnalyticsProperties,
    orderNumber: t.String,
  }),

  [OBJECTS.INBOX]: t.struct({
    ...WithAnalyticsProperties,
    /** it is unintuitive, but `filter` refers to the "selected tab" in an inbox
     * > for example `Active`, `Overdue`, etc... */
    filter: t.String,
    sort: maybe(t.String),
    inboxId: t.String,
    inboxName: t.String,
    options: t.struct({
      unreadOnly: maybe(t.Boolean),
      inboxLoadTime: maybe(t.Number),
      filterType: maybe(t.String),
    }),
    screenAndWindowSizes: t.struct({
      innerWidth: t.Number,
      innerHeight: t.Number,
      screenWidth: t.Number,
      screenHeight: t.Number,
      // `screenAvail` is not availalbe in mobile with the current API being used
      screenAvailHeight: maybe(t.Number),
      screenAvailWidth: maybe(t.Number),
    }),
    // Tracking the fontscale is currently used for mobile only
    fontScale: maybe(t.Number),
  }),

  [OBJECTS.INSIGHTS_PAGE]: t.struct({
    route: t.String,
    query: maybe(t.String),
    dateUnit: maybe(t.String),
    dateRange: maybe(t.String),
    isFilteringPlaces: t.String,
    withError: maybe(t.Boolean),
    millisToLoad: maybe(t.Number),
  }),
  [OBJECTS.MANAGER_DASHBOARD_TEAM_TABLE_ROW]: t.struct({
    ...WithAnalyticsProperties,
    teamMemberName: maybe(t.String),
    teamMemberId: maybe(t.String),
  }),

  [OBJECTS.MANAGER_DASHBOARD_STATS_CARD]: t.struct({
    ...WithAnalyticsProperties,
    cardType: maybe(t.String),
  }),

  [OBJECTS.MANAGER_DASHBOARD_PLACE_SUMMARY_ROW]: t.struct({
    ...WithAnalyticsProperties,
    placeName: maybe(t.String),
    inboxId: maybe(t.String),
    placeId: maybe(t.String),
  }),

  [OBJECTS.MANAGER_DASHBOARD_JOB_ROLE_FILTER]: t.struct({
    ...WithAnalyticsProperties,
    selectedRoles: maybe(t.list(t.String)),
  }),

  [OBJECTS.MAIN_MENU_NAV_BUTTON]: t.struct({
    ...WithAnalyticsProperties,
    sourcePagePath: t.String,
    targetPagePath: t.String,
    prevInboxId: maybe(t.String),
  }),

  [OBJECTS.MENTION]: t.struct({
    ...WithAnalyticsProperties,
    conversationId: t.String,
    placeId: t.String,
    userId: t.String,
  }),

  [OBJECTS.MESSAGE]: t.struct({
    conversationId: t.String,
    feedback: maybe(t.String),
    from: t.String,
    messageId: t.String,
    to: t.String,
    accountId: maybe(t.String),
    placeId: t.String,
    composeId: maybe(t.String),
    type: maybe(t.String),
  }),

  [OBJECTS.MESSAGE_REASON_SENT]: t.struct({
    ...WithAnalyticsProperties,
    placeId: t.String,
    reasonType: t.String,
    reasonName: t.String,
  }),

  [OBJECTS.NUMA_NAV_LOGO]: t.struct({
    accountId: t.String,
    accountName: t.String,
  }),

  [OBJECTS.OUTBOUND_CALL]: t.struct({
    ...WithAnalyticsProperties,
    recordingEnabled: maybe(t.Boolean),
    voicemailDropEnabled: maybe(t.Boolean),
  }),

  [OBJECTS.REPORT_QUESTION_BOT_MISTAKE]: t.struct({
    conversationId: t.String,
    bookedSuccessfully: t.Boolean,
    feedback: maybe(t.String),
    inboxId: t.String,
    placeId: t.String,
    participantId: t.String,
    userId: t.String,
    appointmentData: maybe(t.String),
  }),

  [OBJECTS.SAVED_REPLY]: t.struct({
    ...WithAnalyticsProperties,
    placeId: t.String,
    placeName: t.String,
    conversationId: t.String,
    name: t.String,
    source: maybe(t.String),
  }),

  [OBJECTS.SEARCH]: t.struct({
    inboxType: t.String,
    searchTerm: t.String,
    resultType: maybe(t.String),
  }),

  [OBJECTS.SHORTCUTS]: t.struct({
    ...WithAnalyticsProperties,
    placeId: t.String,
    placeName: t.String,
    conversationId: t.String,
  }),

  [OBJECTS.SIDE_BAR]: t.struct({
    ...WithAnalyticsProperties,
  }),

  [OBJECTS.SIDE_BAR_INBOX_BUTTON]: t.struct({
    ...WithAnalyticsProperties,
    prevInboxId: maybe(t.String),
    newInboxId: t.String,
    hasInboxChanged: t.Boolean,
    sourcePagePath: t.String,
    targetPagePath: t.String,
  }),

  [OBJECTS.SUGGESTION]: t.struct({
    conversationId: t.String,
    suggestionId: t.String,
  }),

  [OBJECTS.SETTINGS_SCREEN]: t.struct({
    blocked: t.Boolean,
    path: t.String,
    title: t.String,
  }),

  [OBJECTS.TEXT_EXPAND]: t.struct({
    accountId: t.String,
    placeId: t.String,
    conversationId: t.String,
    userId: t.String,
  }),

  [OBJECTS.THEME_MODE]: t.struct({
    ...WithAnalyticsProperties,
    from: t.String,
    to: t.String,
  }),

  [OBJECTS.USER]: t.struct({
    memberId: maybe(t.String),
    memberEmail: maybe(t.String),
  }),

  [OBJECTS.VIDEO_CONFERENCE_URL]: t.struct({
    ...WithAnalyticsProperties,
    placeId: t.String,
    placeName: t.String,
    conversationId: t.String,
  }),

  [OBJECTS.VIEW_PREFERENCE]: t.struct({
    ...WithAnalyticsProperties,
    view: t.String,
    previousValue: t.String,
    newValue: t.String,
  }),

  [OBJECTS.ADD_USER]: t.struct({
    accountId: t.String,
    accountName: t.String,
    placeId: t.String,
    tool: t.String,
    currentStep: t.String,
    addUserFlowId: maybe(t.String),
    userId: maybe(t.String),
    basicOptions: t.struct({
      role: maybe(t.String),
      selectedPlaces: maybe(t.list(t.String)),
    }),
    devicesOptions: t.struct({
      deskPhoneEntered: maybe(t.Boolean),
      deskPhoneBusinessHours: maybe(t.Boolean),
      deskPhoneAfterHours: maybe(t.Boolean),
      deskPhoneAway: maybe(t.Boolean),
      mobilePhoneEntered: maybe(t.Boolean),
      mobilePhoneBusinessHours: maybe(t.Boolean),
      mobilePhoneAfterHours: maybe(t.Boolean),
      mobilePhoneAway: maybe(t.Boolean),
    }),
    phoneOptions: t.struct({
      phoneLineSource: maybe(t.String),
      phoneLineId: maybe(t.String),
    }),
    advancedOptions: t.struct({
      selectedTeams: maybe(t.list(t.String)),
    }),
    errorMessage: maybe(t.String),
  }),

  [OBJECTS.WRITE_IT_FOR_ME]: t.struct({
    ...WithAnalyticsProperties,
    conversationId: maybe(t.String),
    repairOrderId: maybe(t.String),
    generationId: t.String,
    clickedQuestionId: maybe(t.String),
    selectedAnswerId: maybe(t.String),
    isSelectedAnswersIdenticalToDefaultBranch: maybe(t.Boolean),
    intent: maybe(t.String),
  }),

  [OBJECTS.OUTBOUND_CALL]: t.struct({
    ...WithAnalyticsProperties,
    source: t.String,
  }),

  [OBJECTS.TO_DO_BANNER]: t.struct({
    ...WithAnalyticsProperties,
    type: t.String,
    action: t.String,
  }),
};

const OBJECTS_TYPE = t.enums.of(values(OBJECTS), 'Objects');
const ACTIONS_TYPE = t.enums.of(values(ACTIONS), 'Action');

export type AnalyticsTrackEvent = {
  object: (typeof OBJECTS)[keyof typeof OBJECTS];
  action: (typeof ACTIONS)[keyof typeof ACTIONS];
  properties?: Record<string, unknown>;
};

class ValidationError extends Error {
  errorMessages: Array<string>;

  constructor(message: string, validationResult?: ValidationResult) {
    super();
    this.errorMessages = [];
    if (validationResult) {
      const errorMessages = validationResult.errors.map(e => e.message);
      this.errorMessages = errorMessages;
      const firstError = validationResult.firstError();
      this.message = `${message}: ${firstError?.message}`;
    } else {
      this.message = message;
    }
  }
}

export const validateEvent = (event: AnalyticsTrackEvent) => {
  const objectValidation = validate(
    event.object,
    OBJECTS_TYPE,
    strictValidation,
  );

  if (!objectValidation.isValid()) {
    throw new ValidationError('Invalid object', objectValidation);
  }

  const actionValidation = validate(
    event.action,
    ACTIONS_TYPE,
    strictValidation,
  );

  if (!actionValidation.isValid()) {
    throw new ValidationError('Invalid action', actionValidation);
  }

  if (
    !event.properties &&
    PROPERTIES_TYPE[event.object] &&
    Object.keys(PROPERTIES_TYPE[event.object].meta.props).length > 0
  ) {
    throw new ValidationError(
      `Must provide properties for object ${event.object}`,
    );
  }

  if (event.properties) {
    const propertyValidation = validate(
      event.properties,
      PROPERTIES_TYPE[event.object],
      strictValidation,
    );

    if (!propertyValidation.isValid()) {
      throw new ValidationError('Invalid properties', propertyValidation);
    }
  }
  return true;
};

export const createEvent = (
  object: AnalyticsTrackEvent['object'],
  action: AnalyticsTrackEvent['action'],
  properties?: Record<string, unknown>,
) => {
  const event = { object, action, properties };
  validateEvent(event);
  return event;
};
